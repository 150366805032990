import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const OrderCompiler = () => {
  const [inputText, setInputText] = useState('');
  const [cumulativeOrders, setCumulativeOrders] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [archives, setArchives] = useState([]);
  const [showArchives, setShowArchives] = useState(false);
  const [selectedArchives, setSelectedArchives] = useState([]);

  useEffect(() => {
    const savedOrders = localStorage.getItem('cumulativeOrders');
    const savedArchives = localStorage.getItem('archives');
    const savedAuth = localStorage.getItem('isAuthenticated');
    if (savedOrders) setCumulativeOrders(JSON.parse(savedOrders));
    if (savedArchives) setArchives(JSON.parse(savedArchives));
    if (savedAuth) setIsAuthenticated(JSON.parse(savedAuth));
  }, []);

  useEffect(() => {
    localStorage.setItem('cumulativeOrders', JSON.stringify(cumulativeOrders));
    localStorage.setItem('archives', JSON.stringify(archives));
    localStorage.setItem('isAuthenticated', JSON.stringify(isAuthenticated));
  }, [cumulativeOrders, archives, isAuthenticated]);

  const authenticate = () => {
    if (password === 'transports123#') {
      setIsAuthenticated(true);
      localStorage.setItem('isAuthenticated', 'true');
    } else {
      alert('Mot de passe incorrect');
    }
  };

  const parseOrders = (text) => {
    return text.split('Commandes').filter(text => text.trim()).map(order => {
      const lines = order.trim().split('\n').filter(line => !line.includes('undefined') && line.trim() !== '');
      const [dateLab, ...details] = lines;
      const [date, lab] = (dateLab || '').split(' - Laboratoire: ');
      let totals = { L: 0, R: 0, T: 0 };
      const parsedDetails = details.map(detail => {
        if (detail.startsWith('L:') && detail.includes('R:') && detail.includes('T:')) {
          const [L, R, T] = detail.match(/\d+/g).map(Number);
          totals = { L, R, T };
          return null;
        }
        const [name, type] = detail.split(': ');
        return { name, type };
      }).filter(Boolean);

      return {
        date: date || 'Date inconnue',
        lab: lab || 'Laboratoire inconnu',
        details: parsedDetails,
        totals,
        additionalMessage: ''
      };
    });
  };

  const addOrders = () => {
    const newOrders = parseOrders(inputText);
    setCumulativeOrders(prevOrders => {
      const updatedOrders = [...prevOrders];
      newOrders.forEach(newOrder => {
        const existingOrderIndex = updatedOrders.findIndex(
          order => order.date === newOrder.date && order.lab === newOrder.lab
        );
        if (existingOrderIndex !== -1) {
          // Fusionner avec une commande existante
          updatedOrders[existingOrderIndex].details = [
            ...updatedOrders[existingOrderIndex].details,
            ...newOrder.details
          ];
          updatedOrders[existingOrderIndex].totals = {
            L: updatedOrders[existingOrderIndex].totals.L + newOrder.totals.L,
            R: updatedOrders[existingOrderIndex].totals.R + newOrder.totals.R,
            T: updatedOrders[existingOrderIndex].totals.T + newOrder.totals.T
          };
        } else {
          // Ajouter une nouvelle commande
          updatedOrders.push(newOrder);
        }
      });
      return updatedOrders.sort((a, b) => new Date(a.date) - new Date(b.date));
    });
    setInputText('');
  };

  const compileOrders = () => {
    setCumulativeOrders(parseOrders(inputText));
    setInputText('');
  };

  const removeOrder = (index) => {
    setCumulativeOrders(prevOrders => prevOrders.filter((_, i) => i !== index));
  };

  const editOrder = (index, updatedFields) => {
    setCumulativeOrders(prevOrders => 
      prevOrders.map((order, i) => 
        i === index ? { ...order, ...updatedFields } : order
      )
    );
  };

  const calculateTotals = (orders) => {
    return orders.reduce((acc, order) => {
      acc.L += order.totals.L;
      acc.R += order.totals.R;
      acc.T += order.totals.T;
      return acc;
    }, { L: 0, R: 0, T: 0 });
  };

  const resetAll = () => {
    if (window.confirm('Êtes-vous sûr de vouloir effacer toutes les commandes ?')) {
      setCumulativeOrders([]);
      localStorage.removeItem('cumulativeOrders');
    }
  };

  const formatOrdersText = (orders) => {
    const orderText = orders.map(order => 
      `${order.date} - Laboratoire: ${order.lab}\n` +
      order.details.map(d => `${d.name}: ${d.type}`).join('\n') +
      `\nL:${order.totals.L} R:${order.totals.R} T:${order.totals.T}` +
      (order.additionalMessage ? `\nMessage: ${order.additionalMessage}` : '')
    ).join('\n\n');
    const totals = calculateTotals(orders);
    return `${orderText}\n\nTotaux:\nL = Livraison: ${totals.L}\nR = Retour: ${totals.R}\nT = Total du jour: ${totals.T}`;
  };

  const sendMessage = (platform) => {
    const content = formatOrdersText(cumulativeOrders);
    let url;
    switch(platform) {
      case 'whatsapp':
        url = `https://wa.me/?text=${encodeURIComponent(content)}`;
        window.open(url, '_blank');
        break;
      case 'sms':
        url = `sms:?body=${encodeURIComponent(content)}`;
        window.location.href = url;
        break;
      case 'email':
        const blob = new Blob([content], { type: 'text/plain' });
        const fileUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = fileUrl;
        link.download = 'commandes.txt';
        link.click();
        break;
    }
  };

  const saveArchive = () => {
    const today = new Date();
    const archiveDate = today.toISOString().split('T')[0];
    const archiveContent = formatOrdersText(cumulativeOrders);
    const newArchive = { date: archiveDate, content: archiveContent };
    
    setArchives(prevArchives => [...prevArchives, newArchive]);
    setCumulativeOrders([]);
  };

  const deleteArchive = (index) => {
    setArchives(prevArchives => prevArchives.filter((_, i) => i !== index));
  };

  const openArchive = (index) => {
    alert(archives[index].content);
  };

  const sendArchiveByEmail = () => {
    const selectedContent = selectedArchives.map(index => archives[index].content).join('\n\n');
    const emailBody = encodeURIComponent(selectedContent);
    window.location.href = `mailto:contact@transportsmarcel.ovh?subject=Archives sélectionnées&body=${emailBody}`;
  };

  const toggleArchiveSelection = (index) => {
    setSelectedArchives(prevSelected => 
      prevSelected.includes(index)
        ? prevSelected.filter(i => i !== index)
        : [...prevSelected, index]
    );
  };

  const printOrders = () => {
    const printContent = formatOrdersText(cumulativeOrders);
    const printWindow = window.open('', '_blank');
    printWindow.document.write(`
      <html>
        <head>
          <title>Commandes du jour</title>
          <style>
            body { font-family: Arial, sans-serif; }
            h1 { text-align: center; }
            pre { white-space: pre-wrap; }
          </style>
        </head>
        <body>
          <h1>Commandes du jour</h1>
          <pre>${printContent}</pre>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.print();
  };

  const buttonStyle = {
    padding: '12px 20px',
    margin: '5px',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    color: 'white',
    fontSize: '16px',
    fontWeight: 'bold',
    width: '100%',
    textAlign: 'center',
  };

  const ArchiveSection = () => (
    <div style={{ marginTop: '20px', border: '2px solid #4a5568', padding: '15px', borderRadius: '10px', backgroundColor: '#f7fafc' }}>
      <h3 style={{ marginBottom: '15px', color: '#2d3748', textAlign: 'center', fontSize: '18px' }}>Archives</h3>
      <button onClick={() => setShowArchives(false)} style={{ ...buttonStyle, backgroundColor: '#4299e1', marginBottom: '15px' }}>
        Retour aux commandes
      </button>
      {archives.length === 0 ? (
        <p>Aucune archive disponible.</p>
      ) : (
        <>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ padding: '10px', borderBottom: '1px solid #e2e8f0' }}>Date</th>
                <th style={{ padding: '10px', borderBottom: '1px solid #e2e8f0' }}>Actions</th>
                <th style={{ padding: '10px', borderBottom: '1px solid #e2e8f0' }}>Sélectionner</th>
              </tr>
            </thead>
            <tbody>
              {archives.map((archive, index) => (
                <tr key={index} style={{ borderBottom: '1px solid #e2e8f0' }}>
                  <td style={{ padding: '10px' }}>{archive.date}</td>
                  <td style={{ padding: '10px' }}>
                    <button onClick={() => openArchive(index)} style={{ ...buttonStyle, backgroundColor: '#48bb78', padding: '5px 10px', fontSize: '14px', width: 'auto', marginRight: '5px' }}>Ouvrir</button>
                    <button onClick={() => deleteArchive(index)} style={{ ...buttonStyle, backgroundColor: '#f56565', padding: '5px 10px', fontSize: '14px', width: 'auto' }}>Supprimer</button>
                  </td>
                  <td style={{ padding: '10px' }}>
                    <input 
                      type="checkbox" 
                      checked={selectedArchives.includes(index)}
                      onChange={() => toggleArchiveSelection(index)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button 
            onClick={sendArchiveByEmail} 
            style={{ ...buttonStyle, backgroundColor: '#9f7aea', marginTop: '15px' }}
            disabled={selectedArchives.length === 0}
          >
            Envoyer les archives sélectionnées par email
          </button>
        </>
      )}
    </div>
  );

  if (!isAuthenticated) {
    return (
      <div style={{ maxWidth: '100%', margin: '0 auto', padding: '10px', fontFamily: 'Arial, sans-serif' }}>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <h2>Accès sécurisé</h2>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Entrez le mot de passe"
          style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
        />
        <button onClick={authenticate} style={{ ...buttonStyle, width: '100%', backgroundColor: '#4299e1' }}>
          Se connecter
        </button>
      </div>
    );
  }

  return (
    <div style={{ maxWidth: '100%', margin: '0 auto', padding: '10px', fontFamily: 'Arial, sans-serif' }}>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {showArchives ? (
        <ArchiveSection />
      ) : (
        <>
          <div style={{ marginBottom: '20px', border: '2px solid #e53e3e', padding: '15px', borderRadius: '10px', backgroundColor: '#fff5f5' }}>
            <h2 style={{ color: '#e53e3e', marginBottom: '15px', textAlign: 'center', fontSize: '20px' }}>TransportsMarcel - Compilateur</h2>
            <textarea
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              placeholder="Collez ici les messages de commandes..."
              style={{ width: 'calc(100% - 20px)', height: '120px', marginBottom: '15px', padding: '10px', borderRadius: '5px', border: '1px solid #e53e3e', fontSize: '16px' }}
            />
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <button onClick={addOrders} style={{ ...buttonStyle, backgroundColor: '#4299e1' }}>
                Ajouter à la liste
              </button>
              <button onClick={compileOrders} style={{ ...buttonStyle, backgroundColor: '#48bb78' }}>
                Nouvelle compilation
              </button>
              <button onClick={resetAll} style={{ ...buttonStyle, backgroundColor: '#f56565', marginTop: '10px' }}>
                Remise à zéro
              </button>
            </div>
          </div>
            {cumulativeOrders.length > 0 && (
  <div style={{ border: '2px solid #4a5568', padding: '15px', borderRadius: '10px', backgroundColor: '#f7fafc' }}>
    <h3 style={{ marginBottom: '15px', color: '#2d3748', textAlign: 'center', fontSize: '18px' }}>Liste compilée des commandes</h3>
    {cumulativeOrders.map((order, index) => (
      <div key={`${order.date}-${order.lab}`} style={{ marginBottom: '20px', borderBottom: '1px solid #e2e8f0', paddingBottom: '15px' }}>
        <h4 style={{ color: '#2b6cb0', margin: 0, fontSize: '16px' }}>{order.date} - Laboratoire: {order.lab}</h4>
        <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
          {order.details.map((detail, idx) => (
            <li key={idx} style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px', fontSize: '14px' }}>
              <span style={{ fontWeight: 'bold' }}>{detail.name}</span>
              <span style={{ color: '#4a5568' }}>{detail.type}</span>
            </li>
          ))}
        </ul>
        <p style={{ fontSize: '14px', marginTop: '5px' }}>
          L:{order.totals.L} R:{order.totals.R} T:{order.totals.T}
        </p>
        <textarea
          value={order.additionalMessage || ''}
          onChange={(e) => editOrder(index, { additionalMessage: e.target.value })}
          placeholder="Message supplémentaire..."
          style={{ width: '100%', marginTop: '5px', padding: '5px' }}
        />
        <button onClick={() => removeOrder(index)} style={{ ...buttonStyle, backgroundColor: '#f56565', width: 'auto', marginTop: '5px' }}>
          Supprimer
        </button>
      </div>
    ))}
    <div style={{ marginTop: '20px', padding: '15px', backgroundColor: '#e2e8f0', borderRadius: '5px' }}>
      <h4 style={{ marginBottom: '10px', color: '#2d3748', fontSize: '16px' }}>Totaux</h4>
      {(() => {
        const totals = calculateTotals(cumulativeOrders);
        return (
          <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
            <p>L = Livraison: <span style={{ color: '#2b6cb0' }}>{totals.L}</span></p>
            <p>R = Retour: <span style={{ color: '#2b6cb0' }}>{totals.R}</span></p>
            <p>T = Total du jour: <span style={{ color: '#2b6cb0' }}>{totals.T}</span></p>
          </div>
        );
      })()}
    </div>
    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', marginTop: '15px' }}>
      <button onClick={() => sendMessage('whatsapp')} style={{ ...buttonStyle, backgroundColor: '#25D366' }}>
        WhatsApp
      </button>
      <button onClick={() => sendMessage('sms')} style={{ ...buttonStyle, backgroundColor: '#FF5722' }}>
        SMS
      </button>
      <button onClick={() => sendMessage('email')} style={{ ...buttonStyle, backgroundColor: '#007AFF' }}>
        Fichier texte
      </button>
      <button onClick={printOrders} style={{ ...buttonStyle, backgroundColor: '#718096' }}>
        Imprimer la liste du jour
      </button>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '15px' }}>
      <button onClick={saveArchive} style={{ ...buttonStyle, backgroundColor: '#9f7aea', width: 'auto' }}>
        Enregistrer
      </button>
    </div>
  </div>
)}

<button onClick={() => setShowArchives(true)} style={{ ...buttonStyle, marginTop: '20px', backgroundColor: '#2d3748' }}>
  Afficher les archives
</button>
</>
)}
</div>
);
};

export default OrderCompiler;
